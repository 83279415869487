import { createSlice } from '@reduxjs/toolkit'

export const menuInfoSlice = createSlice({
  name: 'menuInfo',
  initialState: {
    collapsed: false,
    curPrefix: '',
    openKey: [],
    selectedKey: [],
    curLeftMenus: [],
    authority:[],
  },
  reducers: {
    // 菜单收起展开
    setMenuCollapsed: (state, { payload }) => {
      state.collapsed = payload
    },
    // 菜单展开子级
    setMenuOpened: (state, { payload }) => {
      state.openKey = [...payload]
    },
    // 菜单展开子级
    setMenuSelected: (state, { payload: { selectedKey, openKey, curPrefix, curLeftMenus } }) => {
      // console.log('setmenuSelect: ', openKey)
      state.selectedKey = [...selectedKey]
      // 设置默认打开菜单
      state.openKey = [...openKey]
      // 当前选中一级导航path
      curPrefix && (state.curPrefix = curPrefix)
      // 左侧菜单项根据curPrefix动态更新
      curLeftMenus && (state.curLeftMenus = [...curLeftMenus])
    },
    setAuthority: (state,{payload}) =>{
      state.authority = [...payload]
    }
  }
})

export const { setMenuCollapsed, setMenuOpened, setMenuSelected ,setAuthority} = menuInfoSlice.actions
export default menuInfoSlice.reducer
