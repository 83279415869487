import { createSlice } from '@reduxjs/toolkit'

export const loginUserSlice = createSlice({
  name: 'loginUser',
  initialState: {
    epName: '',
    name: '',
    optNum: '',
    userAdminSn: '',
    lemsPlatform: '',
    tenantNum: '',
    lemsAuthCode: '',
    lemsOrgCode: '',
    companyName: '',
    appid: '',
    isCreatedSecret: false
  },
  reducers: {
    // 保存登录用户信息
    setLoginUserInfo: (state, { payload: { epName, name, optNum, userAdminSn, lemsPlatform, tenantNum, lemsAuthCode, lemsOrgCode } }) => {
      state.epName = epName
      state.name = name
      state.optNum = optNum
      state.userAdminSn = userAdminSn
      state.lemsPlatform = lemsPlatform
      state.tenantNum = tenantNum
      state.lemsAuthCode = lemsAuthCode
      state.lemsOrgCode = lemsOrgCode
      // 每次登陆后会重置
      state.companyName = ''
      state.appid = ''
      state.isCreatedSecret = false
    },
    // 保存公司信息
    setCompanyInfo: (state, { payload: { companyName, appid, isCreatedSecret } }) => {
      state.companyName = companyName
      state.appid = appid
      state.isCreatedSecret = isCreatedSecret // 密钥生成状态
    },
    // 保存密钥重置状态信息
    setCreatedSecretStatus: (state, { payload }) => {
      state.isCreatedSecret = payload // 更新密钥生成状态
    }
  }
})

export const { setLoginUserInfo, setCompanyInfo, setCreatedSecretStatus } = loginUserSlice.actions
export default loginUserSlice.reducer
