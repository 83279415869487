import { lazy } from 'react'

import MenuRoutes from './menuRouter'
import { flatArrayObj } from '@utils'

const Routes = [
  {
    path: '/',
    component: lazy(() => import('@layout')),
    // 子菜单转为一维数组，且过滤掉没有指向的菜单
    children: [...flatArrayObj(MenuRoutes, 'children').filter((item) => item.component || item.redirect)]
  },
  // {
  //   path: 'login',
  //   component: lazy(() => import('@pages/login'))
  // },
  {
    path: '*',
    component: lazy(() => import('@common/404'))
  },
  {
    path: '/transit',
    component: lazy(() => import('@common/transit'))
  }
]

export default Routes
