import { lazy } from "react";
import { Navigate } from "react-router-dom";
// 平台级1 租户级2
const MenuRoutes = [
	{
		path: "home",
		name: "首页",
		icon: "icon-renyuanxinxi",
		component: lazy(() => import("@pages/home")),
		id: "102",
		platform: 2,
		disabled: false,
	},
	{
		path: "unAuthority",
		name: "无权限",
		icon: "icon-renyuanxinxi",
		component: lazy(() => import("@/pages/unAuthority/unAuthority")),
		disabled: true,
	},
	{
		path: "sysOptMaintenance",
		name: "系统运维",
		icon: "icon-xitongyunwei",
		id: "10201",
		disabled: false,
		redirect: (
			<Navigate
				to="/sysOptMaintenance/tenantManagement"
				replace={true}
			/>
		),
		children: [
			{
				path: "sysOptMaintenance/systemConfig",
				name: "系统配置",
				id: "1020101",
				icon: "icon-xitongpeizhi",
				component: lazy(() => import("@/pages/sysOptMaintenance/systemConfig")),
				platform: 2,
				disabled: false,
			},
			{
				path: "sysOptMaintenance/tenantManagement",
				name: "租户管理",
				icon: "icon-zuhuguanli",
				id: "1020102",
				component: lazy(() =>
					import("@pages/sysOptMaintenance/tenantManagement")
				),
				platform: 1,
				disabled: false,
			},
			{
				path: "sysOptMaintenance/equipmentOperation",
				name: "设备监控运维",
				icon: "icon-shebeijiankongyunwei",
				id: "1020103",
				component: lazy(() =>
					import("@pages/sysOptMaintenance/equipmentOperation")
				),
				platform: 2,
				disabled: false,
			},
		],
		platform: 2,
		// hideInMenu: Number(getParams(useLocation().search.substr(1), 'platform')) === 2
	},
	{
		path: "chargePileManagement",
		name: "充电桩管理",
		id: "10202",
		disabled: false,
		icon: "icon-chongdianzhuangguanli1",
		redirect: (
			<Navigate
				to="/chargePileManagement/sceneConfig"
				replace={true}
			/>
		),
		children: [
			{
				path: "chargePileManagement/sceneConfig",
				name: "场景管理",
				icon: "icon-changjingguanli",
				id: "1020201",
				component: lazy(() =>
					import("@pages/chargePileManagement/sceneConfig")
				),
				platform: 2,
				disabled: false,
			},
			{
				path: "chargePileManagement/priceRuleManage",
				name: "计价规则",
				icon: "icon-jijiaguize",
				id: "1020202",
				component: lazy(() =>
					import("@pages/chargePileManagement/priceRuleManage")
				),
				platform: 2,
				disabled: false,
			},
			{
				path: "chargePileManagement/deviceManage",
				name: "设备管理",
				icon: "icon-shebeiguanli",
				id: "1020203",
				component: lazy(() =>
					import("@pages/chargePileManagement/deviceManage")
				),
				platform: 2,
				disabled: false,
			},
			{
				path: "chargePileManagement/TransactionFlowReconciliation",
				name: "交易流水对账",
				icon: "icon-caozuojiluchaxun",
				id: "1020204",
				component: lazy(() =>
					import("@pages/chargePileManagement/TransactionFlowReconciliation")
				),
				platform: 2,
				disabled: false,
			},
			{
				path: "chargePileManagement/systemDailySettlement",
				name: "系统日结",
				icon: "icon-caozuojiluchaxun",
				id: "1020205",
				component: lazy(() =>
				import("@pages/chargePileManagement/systemDailySettlement")
			),
			platform: 2,
			disabled: false,
			}
		],
		platform: 2,
		// hideInMenu: Number(getParams(useLocation().search.substr(1), 'platform')) === 1
	},

	{
		path: "hairdryerManagement",
		name: "吹风机管理",
		id: "10205",
		disabled: false,
		icon: "icon-chongdianzhuangguanli1",
		redirect: (
			<Navigate
				to="/hairdryerManagement/sceneConfig"
				replace={true}
			/>
		),
		children: [
			{
				path: "hairdryerManagement/sceneConfig",
				name: "场景管理",
				icon: "icon-changjingguanli",
				id: "1020501",
				component: lazy(() =>
					import("@pages/hairdryerManagement/sceneConfig")
				),
				platform: 2,
				disabled: false,
			},
			{
				path: "hairdryerManagement/priceRuleManage",
				name: "计价规则",
				icon: "icon-jijiaguize",
				id: "1020502",
				component: lazy(() =>
					import("@pages/hairdryerManagement/priceRuleManage")
				),
				platform: 2,
				disabled: false,
			},
			{
				path: "hairdryerManagement/deviceManage",
				name: "设备管理",
				icon: "icon-shebeiguanli",
				id: "1020503",
				component: lazy(() =>
					import("@pages/hairdryerManagement/deviceManage")
				),
				platform: 2,
				disabled: false,
			},
			{
				path: "hairdryerManagement/TransactionFlowReconciliation",
				name: "交易流水对账",
				icon: "icon-caozuojiluchaxun",
				id: "1020504",
				component: lazy(() =>
					import("@pages/hairdryerManagement/TransactionFlowReconciliation")
				),
				platform: 2,
				disabled: false,
			},
			{
				path: "hairdryerManagement/systemDailySettlement",
				name: "系统日结",
				icon: "icon-caozuojiluchaxun",
				id: "1020505",
				component: lazy(() =>
				import("@pages/hairdryerManagement/systemDailySettlement")
			),
			platform: 2,
			disabled: false,
			}
		],
		platform: 2,
	},

	{
		path: "orderManagement",
		name: "业务订单",
		id: "10203",
		icon: "icon-dingdanguanli",
		component: lazy(() => import("@pages/orderManagement/orderList")),
		platform: 2,
		disabled: false,
	},
	{
		path: "recordQuery",
		name: "记录查询",
		icon: "icon-jiluchaxun",
		disabled: false,
		id: "10204",
		redirect: (
			<Navigate
				to="/recordQuery/operationRecord"
				replace={true}
			/>
		),
		children: [
			{
				path: "recordQuery/operationRecord",
				name: "操作记录查询",
				id: "1020401",
				icon: "icon-caozuojiluchaxun",
				component: lazy(() => import("@pages/recordQuery/operationRecord")),
				platform: 2,
				disabled: false,
			},
			{
				path: "recordQuery/transactionFlow",
				name: "交易流水查询",
				id: "1020402",
				icon: "icon-caozuojiluchaxun",
				component: lazy(() => import("@pages/recordQuery/transactionFlow")),
				platform: 2,
				disabled: false,
			},
			{
				path: "recordQuery/deviceEventManage",
				name: "设备事件记录",
				icon: "icon-caozuojiluchaxun",
				id: "1020403", // 1020204
				component: lazy(() =>
					import("@pages/recordQuery/deviceEventManage")
				),
				platform: 2,
				disabled: false,
			},
		],
		// hideInMenu: Number(getParams(useLocation().search.substr(1), 'platform')) === 1
		platform: 2,
	}
]

export default MenuRoutes
