import { Spin } from 'antd'

const PageLoading = () => {
  return (
    <div className="page-loading-container">
      <Spin />
    </div>
  )
}

export default PageLoading
