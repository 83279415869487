import CryptoJS from 'crypto-js'

/***
 * 以递归的方式展平数组对象
 * @param arr 数组对象
 * @param childKey 子级key
 */
export const flatArrayObj = (arr, childKey) =>
  arr.reduce((prev, item) => {
    let isChild = Array.isArray(item[childKey])
    if (isChild) {
      prev.push(item)
    }
    return prev.concat(isChild ? flatArrayObj(item[childKey], childKey) : item)
  }, [])

/***
 * 防抖
 * @param {Function} fn 延时调用函数
 * @param {Number} delay 延迟多长时间
 */
export const debounce = (fn, delay) => {
  let timer = null
  return function () {
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, arguments)
    }, delay)
  }
}

/***
 * 节流
 * @param {Function} fn 延时调用函数
 * @param {Number} delay 延迟多长时间
 */
export const throttle = (fn, delay) => {
  let flag = true
  return function () {
    if (!flag) {
      return
    }
    flag = false
    setTimeout(() => {
      fn.apply(this, arguments)
      flag = true
    }, delay)
  }
}

/***
 * 字符串加密
 * @param {Number} String 要加密的字符串
 */
export const encryptStr = (str) => {
  return CryptoJS.MD5(`${str}Hzlinks2021`).toString()
}

export const getParams = (search, key) => {
  // let search = window.location.search.replace(/^\?/, "");
  let pairs = search.split("&");
  
  let paramsMap = pairs.map(pair => {
      let [key, value] = pair.split("=");
      // return [decodeURIComponent(key), decodeURIComponent(value)];
      return [key, value];
  }).reduce((res, [key, value]) => Object.assign(res, { [key]: value }), {});
  return paramsMap[key] || "";
}

