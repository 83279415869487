const antdThemeCustom = {
  token: {
    colorPrimary: '#4084ff',
    colorInfo: '#4084ff',
    colorTextPlaceholder: '#D9DADD',
    fontSizeLG: 14,
    colorText: '#4e5767',
    borderRadiusLG: 4,
    borderRadius: 4
  },
  components: {
    Modal: {
      paddingContentHorizontalLG: 30,
      paddingMD: 30,
      borderRadiusLG: 10,
      marginXS: 16
    },
    Form: {
      colorTextHeading: '#1E2B43',
      colorText: '#1E2B43'
    },
    Input: {
      colorBorder: '#E4E8EF',
      colorBgContainerDisabled: '#F6FAFD'
    },
    Button: {
      colorBgContainerDisabled: '#B3CEFF',
      colorTextDisabled: '#fff'
    },
    Select: {
      colorBorder: '#E4E8EF'
    }
  }
}
export default antdThemeCustom
