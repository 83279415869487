import { configureStore, combineReducers } from "@reduxjs/toolkit"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

// 引入切片
import menuInfoReducer from "./slice/menuInfoSlice"
import loginUserReducer from "./slice/loginUserSlice"

// 缓存配置
const persistConfig = {
	key: "root",
	storage,
	// 黑名单 不缓存的数据
	blacklist: [],
}

// 合并reducer
const reducers = combineReducers({
	menuInfo: menuInfoReducer,
	loginUser: loginUserReducer,
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
})
export const persistor = persistStore(store)
