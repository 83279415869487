import { Suspense } from "react"
import { createHashRouter } from "react-router-dom"

import routes from "./router"
import PageLoading from "@common/pageLoading"

const syncRouter = (tree) => {
	const routeLoop = (arr) => {
		return arr.map((route) => {
			let routeObj = {
				path: route.path,
				element: route.redirect ? (
					route.redirect
				) : (
					// 开启路由懒加载
					<Suspense fallback={<PageLoading />}>{<route.component />}</Suspense>
				),
			}
			route.children && (routeObj["children"] = routeLoop(route.children))
			return routeObj
		})
	}

	return routeLoop(tree)
}

export default createHashRouter(syncRouter(routes))
